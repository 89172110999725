import styled from "styled-components"

export const DirectionButtonsDiv = styled.div`
grid-column: 3;
grid-row: 4;
display: grid;
max-height: 14rem;
place-self: center;
margin-left: 1rem;


`