/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 public/static/skeletonEnemy.glb
*/

import React, { useMemo, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { CuboidCollider, InstancedRigidBodies } from '@react-three/rapier'

export const SkeletonEnemyModel = (props) =>  {
  const { nodes, materials } = useGLTF(process.env.PUBLIC_URL+'/static/skeletonEnemy.glb')

  const count = 30
  const sekeltonRef = useRef()

  const instances = useMemo(() => {
    const instances = []
    for (let i = 0; i < count ; i++){
      instances.push({
         key: "instance_" + Math.random(),
         position: [Math.random() * 400, 16.9, Math.random() * 400],
         rotation: [-Math.PI / 2, 0, -Math.PI / Math.random()]
      })
    }
    return instances
  }, [])

  const offset = 16
  return (
      <InstancedRigidBodies
        ref={sekeltonRef} 
        instances={instances}
        colliders={false}
        scale={0.5}
        colliderNodes={[
          <CuboidCollider args={[5, 5, 5]} position={[0, 0, 21 -offset]}/>,
          <CuboidCollider args={[5, 5, 5]} position={[0, 0, 11 -offset]}/>,
          <CuboidCollider args={[2.5, 2.5, 6]} position={[0, 7.5, 10 -offset]}/>,
          <CuboidCollider args={[2.5, 2.5, 6]} position={[0, -7.5, 10 -offset]}/>,
          <CuboidCollider args={[5, 5, 6]} position={[0, 0, 0 -offset]}/>,
        ]}
        >
      <instancedMesh frustumCulled={false} args={[undefined, undefined, count]} count={count} geometry={nodes.head003.geometry} material={materials.spike_metal_material}/>
      
      </InstancedRigidBodies>
  )

}

useGLTF.preload(process.env.PUBLIC_URL+'/static/skeletonEnemy.glb')
